
    import { mapState } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'PledgeCompletedModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'pledge-completed-modal',
            };
        },
        i18nOptions: { namespaces: ['pledge_modal', 'buttons'] },
        methods: {
            closePopup () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Pledge_Complete' });
                this.closeModal();
            },
        },
    };
