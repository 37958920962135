
    import BaseModal from './BaseModal';

    export default {
        name: 'ViralThanksModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['viral_thanks'] },
        methods: {
            closeViralThanksModal () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Taf_Confirm' });
                this.closeModal();
            },
        },

    };
