
    import { mapActions, mapGetters, mapState } from 'vuex';
    import convertBrandToLongName from '../mixins/convertBrandToLongName';
    import BaseModal from './BaseModal';
    // import store from '../store/index';

    export default {
        name: 'BrandGateModal',


        extends: BaseModal,

        mixins: [convertBrandToLongName],

        props: {
            selectedBrand: {
                type: String,
                default: '',
            },
        },

        data () {
            return {
                brand: '',
                modalName: 'brand-selection',
            };
        },

        i18nOptions: { namespaces: ['buttons', 'brand_gate', 'brand_selection'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            brandList () {
                if (!this.ui.brandOptions.length) {
                    return [{ value: '', label: '' }];
                }

                const brands = [];

                for (const brand of this.ui.brandOptions) {
                    const tempBrand = {
                        value: brand.value,
                        label: this.$t(`brands.${brand.value}`),
                    };
                    brands.push(tempBrand);
                }
                return brands;
            },
        },

        mounted () {
            this.btnname = this.$t('next_btn');
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
                updateProfile: 'profile/updateProfile',
            }),

            goToBrand (stayOrGo) {
                const preferredOrNewBrand = stayOrGo ? this.convertBrandToLongName(this.selectedBrand) : this.convertBrandToLongName(this.profile.preferred_brand);
                this.$router.push(`/${preferredOrNewBrand}`);
                this.closeModal();
            },
        },
    };
