
    import openModal, { HowItWorksModal } from '@public/js/modals';
    import BoxIcon from '@components/svg/BoxIcon.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'EnrollmentConfirmationModal',

        components: {
            BoxIcon,
        },

        extends: BaseModal,

        props: {
            showEnrollmentConfirmationModal: {
                type: Boolean,
                default: false,
            },
        },

        data () {
            return {
                modalName: 'enrollment-confirmation',
            };
        },

        methods: {
            closeRegThanksModal () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Reg_Confirm' });

                if (this.showEnrollmentConfirmationModal) {
                    openModal(HowItWorksModal);
                }
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['reg_thanks', 'buttons'] },
    };
