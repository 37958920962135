import openModal, { AddressUpdateModal } from '../modals';
export default {
    methods: {
        handleAddressVerifyModals (data) {
            if (data.hasErrors || data.addressMessage) {
                const errors = [];
                for (const error of data) {
                    if (error.error === 'no_po_box' || data.addressMessage?.msg === 'P') {
                        errors.push('no_po_box');
                    }
                    else {
                        errors.push(error.name);
                    }
                }
                return errors;
            }
            else {
                const addressResponseData = data.data || data;
                const addressMessage = addressResponseData?.addressMessage?.msg;

                if (addressMessage && addressMessage === 'bad address') {
                    // check if modal is already open to avoid multiple modals
                    if (document.getElementsByClassName('address-update-modals').length === 0) {
                        openModal(AddressUpdateModal);
                    }
                    return 'bad address';
                }
                else {
                    console.log('no address updates needed');
                    if (this.$route.query.catalog_rewards) {
                        this.$router.push('/rewards_catalog');
                    }
                    else if (this.$route.path !== '/dashboard') {
                        this.$router.replace('/');
                    }
                    return true;
                }
            }
        },
    },
};
