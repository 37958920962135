import Vue from 'vue';

export default function openModal (component, props) {
    return new Promise((resolve, reject) => {
        const modal = new Vue({
            methods: {
                closeHandler (fn, arg) {
                    fn(arg);
                    modal.$destroy();
                    // use Node.removeChild instead of ChildNode.remove for compatibility
                    // https://caniuse.com/#search=ChildNode.remove()
                    modal.$el.parentNode.removeChild(modal.$el);
                },
            },

            render (createElement) {
                return createElement(component, {
                    props,
                    on: {
                        'close-modal': (arg) => this.closeHandler(resolve, arg),
                        'cancel-modal': (arg) => this.closeHandler(reject, arg),
                    },
                });
            },
        }).$mount();

        document.body.appendChild(modal.$el);
    });
}

export { default as EnrollmentConfirmationModal } from './EnrollmentConfirmationModal.vue';
export { default as AddressConfirmModal } from './AddressConfirmModal.vue';
export { default as AddressUpdateModal } from './AddressUpdateModal.vue';
export { default as AddressUpdateSuccessModal } from './AddressUpdateSuccessModal.vue';
export { default as ErrorModal } from './ErrorModal.vue';
export { default as HowItWorksModal } from './HowItWorksModal.vue';
export { default as BrandSelectionModal } from './BrandSelectionModal.vue';
export { default as BrandGateModal } from './BrandGateModal.vue';
export { default as ParticipationModal } from './ParticipationModal.vue';
export { default as PhotoUploadTipsModal } from './PhotoUploadTipsModal.vue';
export { default as PledgeModal } from './PledgeModal.vue';
export { default as ActivityModal } from './ActivityModal.vue';
export { default as PollModal } from './PollModal.vue';
export { default as PollConfirmModal } from './PollConfirmModal.vue';
export { default as PollResultModal } from './PollResultModal.vue';
export { default as SessionExpirationModal } from './SessionExpirationModal.vue';
export { default as UploadPreviewModal } from './UploadPreviewModal.vue';
export { default as UploadConfirmModal } from './UploadConfirmModal.vue';
export { default as ViralModal } from './ViralModal.vue';
export { default as ViralThanksModal } from './ViralThanksModal.vue';
export { default as PledgeCompletedModal } from './PledgeCompletedModal.vue';
export { default as RewardCatalogModal } from './RewardCatalogModal.vue';
export { default as IncompleteAddressModal } from './IncompleteAddressModal.vue';
export { default as ConfirmRedemptionModal } from './ConfirmRedemptionModal.vue';
export { default as RedemptionConfirmedModal } from './RedemptionConfirmedModal.vue';
export { default as ProfileSurveyModal } from './ProfileSurveyModal.vue';
export { default as ProfileSurveyCompleteModal } from './ProfileSurveyCompleteModal.vue';
export { default as UploadLimitedModal } from './UploadLimitedModal.vue';
export { default as PrizeDetailsModal } from './PrizeDetailsModal.vue';
