
import axios from 'axios';
import store from './store/index';
import api from './api';
import openModal, { ErrorModal } from './modals';

const loyalty = {
    async fetchEventDetails () {
        try {
            const response = await axios.get(`${api.base}/profiles/events`),
                events = response.data.result.data,
                tempProfile = {};

            for (const event of events) {
                tempProfile[event.event_type] = event;
            }

            tempProfile.balance = response.data.result.pointsEarned - response.data.result.pointsRedeemed  - response.data.result.pointsExpired;

            store.commit('profile/updateProfile', tempProfile);
            return tempProfile;
        }
        catch (err) {
            openModal(ErrorModal);
            throw err;
        }
    },

    async fetchCatalog () {
        try {
            const { data } = await axios.get(`${api.base}/rewards`);
            return data.rewards_remap.rewards;
        }
        catch (err) {
            openModal(ErrorModal);
            throw err;
        }
    },

    async recordEvent (event_type) {
        try {
            await axios.post(`${api.base}/profiles/record`, { event_type });
            this.fetchEventDetails();
            return true;
        }
        catch (err) {
            openModal(ErrorModal);
            throw err;
        }
    },

    async redeem (reward_id, external_id) {
        try {
            const response = axios.post(`${api.base}/redeem`, { reward_id, external_id });
            let redeemSuccess;
            await response.then(res => {
                redeemSuccess = res.data.success;

                if (redeemSuccess) {
                    this.fetchEventDetails();
                }
                return;
            });


            return redeemSuccess;
        }
        catch (err) {
            openModal(ErrorModal);
            throw err;
        }
    },
};

export default loyalty;
