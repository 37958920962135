
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import { mapActions } from 'vuex';
    import { BaseCheckbox, BaseInput, BaseSelect } from '@components/form';
    import store from '../store/index';

    import openModal, { ProfileSurveyCompleteModal } from '../modals';
    import dobData from '../utils/dateOfBirth';
    import BaseModal from './BaseModal';

    export default {
        name: 'ProfileSurveyModal',

        components: {
            VueSlickCarousel,
            BaseCheckbox,
            BaseInput,
            BaseSelect,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'ProfileSurvey',
                currentSlide: 1,
                loading: true,
                slides: 4,
                slickSettings: {
                    arrows: false,
                    infinite: false,
                    slidesToScroll: 1,
                    slideToShow: 1,
                    dots: true,
                    adaptiveHeight: true,
                    draggable: false,
                    swipe: false,
                    touchMove: false,
                },
                isProfileUpdate: false,
                ageOptions: dobData,
                tempProfile: {
                    age: '',
                    'age.birth_month': '',
                    'age.birth_day': '',
                    lifestyle_preferences: '',
                    preferred_location: '',
                    retail_preferences: '',
                    ...store.state.profile,
                },
                trackingValue: [],
                questionTypes: ['product', 'retailer', 'lifestyle'],
                surveyAnswers: {
                    product: [],
                    retailer: [],
                    lifestyle: [],
                },
            };
        },
        computed: {
            isZephyr () {
                return this.profile.preferred_brand === 'zephyr';
            },

            productLength () {
                return this.surveyAnswers.product.length || (this.isZephyr ? 5 : 7);
            },

            retailerLength () {
                return this.surveyAnswers.retailer.length || 8;
            },

            lifestyleLength () {
                return this.surveyAnswers.lifestyle.length || 7;
            },

            isAgeSelected () {
                return this.tempProfile['age.birth_day'] && this.tempProfile['age.birth_month'];
            },

            isProductSelected () {
                return this.tempProfile.product1 || this.tempProfile.product2 || this.tempProfile.product3 || this.tempProfile.product4 || this.tempProfile.product5 || this.tempProfile.product6 || (this.tempProfile.product7 && this.tempProfile.product_other);
            },

            isLifestyleSelected () {
                return this.tempProfile.lifestyle1 || this.tempProfile.lifestyle2 || this.tempProfile.lifestyle3 || this.tempProfile.lifestyle4 || this.tempProfile.lifestyle5 || this.tempProfile.lifestyle6 || (this.tempProfile.lifestyle7 && this.tempProfile.lifestyle_other);
            },

            isRetailerSelected () {
                return this.tempProfile.retailer1 || this.tempProfile.retailer2 || this.tempProfile.retailer3 || this.tempProfile.retailer4 || this.tempProfile.retailer5 || this.tempProfile.retailer6 || this.tempProfile.retailer7 || (this.tempProfile.retailer8 && this.tempProfile.retailer_other);
            },

            productName () {
                return this.$t(`brand_selection:brands.${this.profile.preferred_brand}`);
            },
        },

        created () {
            this.getSurveyAnswers();
        },

        updated () {
            if (this.slideIndex > 1) {
                this.moveToSlide(this.slideIndex);
            }
        },

        methods: {
            ...mapActions({
                'updateProfile': 'profile/updateProfile',
            }),

            beforeSlideChange (_, newSlide) {
                this.currentSlide = newSlide + 1;
            },

            prevSlide () {
                this.$refs.carousel.prev();
            },

            nextSlide () {
                this.$refs.carousel.next();
            },

            moveToSlide (slideIndex) {
                this.$refs.carousel.goTo(slideIndex - 1);
            },

            getSurveyAnswers () {
                for (const type of this.questionTypes) {
                    const answers = this.$t(`${type}.${this.profile.preferred_brand}`);
                    this.surveyAnswers[type] = Object.keys(answers).filter(key => key.indexOf('.') <= -1);
                }
                console.log(this.surveyAnswers);
                this.loading = false;
            },

            completeSurvey () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Survey_Complete' });
                this.closeModal();
                openModal(ProfileSurveyCompleteModal);
            },

            populateLifestyleForLP (tempProfileKeys) {
                const lifestyles = [];
                tempProfileKeys.forEach(k => {
                    console.log(k);
                    if (k.includes('lifestyle') && !k.includes('_')) {
                        lifestyles.push(this.$t(`lifestyle.${this.profile.preferred_brand}.${k}`));
                    }
                });

                if (this.tempProfile.lifestyle_other) {
                    lifestyles.push(this.tempProfile.lifestyle_other);
                }

                return lifestyles.join(', ');
            },

            populateProductForLP (tempProfileKeys) {

                const products = [];
                tempProfileKeys.forEach(k => {
                    if (k.includes('product') && !k.includes('_')) {
                        products.push(this.$t(`product.${this.profile.preferred_brand}.${k}`));
                    }
                });

                if (this.tempProfile.product_other) {
                    products.push(this.tempProfile.product_other);
                }

                return products.join(', ');
            },

            populateRetailerForLP (tempProfileKeys) {

                const retailers = [];
                tempProfileKeys.forEach(k => {
                    if (k.includes('retailer') && !k.includes('_')) {
                        retailers.push(this.$t(`retailer.${this.profile.preferred_brand}.${k}`));
                    }
                });

                if (this.tempProfile.retailer_other) {
                    retailers.push(this.tempProfile.retailer_other);
                }

                return retailers.join(', ');
            },

            async doProfileUpdate (e) {
                const { tempProfile } = this;

                if (!this.isRetailerSelected || !this.isLifestyleSelected || !this.isProductSelected || !this.isAgeSelected) {
                    return;
                }

                const tempProfileKeys = Object.keys(this.tempProfile);
                tempProfile.lifestyle_preferences = this.populateLifestyleForLP(tempProfileKeys);
                tempProfile.retail_preferences = this.populateProductForLP(tempProfileKeys);
                tempProfile.preferred_location = this.populateRetailerForLP(tempProfileKeys);

                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Survey_1', value: tempProfile.preferred_location });
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Survey_2', value: tempProfile.retail_preferences });
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Survey_3', value: tempProfile.lifestyle_preferences });

                // This is so the update will add birth day and month to LP
                tempProfile.birthdate = this.tempProfile['age.birth_month'] + '-' + this.tempProfile['age.birth_day'];

                this.loading = true;

                try {
                    await this.updateProfile({ ...tempProfile, profile_survey: 1 });
                    this.isProfileUpdate = false;
                    this.loading = false;
                }
                catch (err) {
                    console.error(err);
                    this.isProfileUpdate = true;
                    this.loading = false;
                }

                this.completeSurvey();
            },

            isSlideComplete (slide) {
                if (slide === 1) {
                    return this.isProductSelected;
                }
                else if (slide === 2) {
                    return this.isRetailerSelected;
                }
                else if (slide === 3) {
                    return this.isLifestyleSelected;
                }
                else if (slide === 4) {
                    return this.isAgeSelected;
                }
            },
        },
        i18nOptions: { namespaces: ['profile_survey', 'registration', 'profile', 'buttons', 'brand_selection'] },
    };
