
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LimiterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LimiterPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    ProfilePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/ProfilePage.vue'
    ),
    DashboardPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DashboardPage.vue'
    ),
    DedicatedBrandPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DedicatedBrandPage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    PausedAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/PausedAccountPage.vue'
    ),
    TransactionHistoryPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/TransactionHistoryPage.vue'
    ),
    RewardsCatalog = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RewardsCatalog.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            if (store.state.profile.is_limited) {
                return '/limiter';
            }
            else {
                return '/dashboard';
            }
        }

        return '/landing';
    } },
    { path: '/login', name: 'login', component: LoginPage, meta: { public: true }, props: true },
    {
        path: '/register',
        component: RegisterPage,
        meta: { public: true },
    },
    { path: '/limiter', component: LimiterPage },
    { path: '/landing', component: LandingPage, meta: { public: true } },
    { path: '/activity-log', component: TransactionHistoryPage },
    { path: '/dashboard', component: DashboardPage },
    { path: '/profile_survey', component: ProfilePage },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/paused_account', component: PausedAccountPage, meta: { public: true } },
    { path: '/rewards_catalog', component: RewardsCatalog },
    {
        path: '/arrowhead',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/deerpark',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/icemountain',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/ozarka',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/polandspring',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/purelife',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/splash',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
    {
        path: '/zephyrhills',
        component: DedicatedBrandPage,
        meta: { public: true },
    },
];
