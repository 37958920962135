
    import BaseModal from './BaseModal';

    export default {
        name: 'RedemptionConfirmedModal',

        i18nOptions: { namespaces: ['rewardcatalog_modal', 'buttons', 'dashboard'] },

        extends: BaseModal,

        props: {
            reward: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'redemptionconfirmed-modal',
            };
        },

        methods: {
            closePopup () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Redemption_Confirmed' });
                this.closeModal();
            },
        },
    };
