
    import { mapState } from 'vuex';
    import VueSlickCarousel from 'vue-slick-carousel';
    import BaseModal from './BaseModal';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';

    export default {
        name: 'HowItWorksModal',

        components: {
            VueSlickCarousel,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'how-it-works',
                currentSlide: 0,
                slides: new Array(3),
                loaded: false,
                btnname: '',
                slidercount: '',
            };
        },

        i18nOptions: { namespaces: ['how_it_works', 'buttons'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),

            btnCopy () {
                return this.btnname;
            },

            brandName () {
                return this.$t(`brands.${this.profile.preferred_brand}`);
            },

            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },

        mounted () {
            this.loaded = true;
            this.btnname = this.$t('next_btn');
        },

        methods: {
            afterSlideChange (slideIndex) {
                this.currentSlide = slideIndex;
                if (this.currentSlide == '2') {
                    this.btnname = this.$t('got_it');
                }
                else {
                    this.btnname = this.$t('next_btn');
                }
            },
            nextslide () {
                const { carousel } = this.$refs;

                this.$webAnalytics.track('Click', { category: 'GoTo', label: `HowItWorks_Onboaring_${this.currentSlide + 1}` });

                if (this.currentSlide < this.slides.length - 1) {
                    carousel.next();
                }
                else {
                    this.closeModal();
                }

                this.sliderCount(this.slides.length);
                if (this.slidercount == -2) {
                    this.btnname = this.$t('got_it');
                }
                if (this.slidercount == -3) {
                    this.closeModal();
                }

            },

            sliderCount () {
                return this.slidercount--;
            },
        },
    };
