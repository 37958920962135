
    import { mapState,  mapMutations } from 'vuex';
    import { getBrand } from '../utils/trackUtils';

    export default {
        name: 'BrandSelect',
        i18nOptions: { namespaces: ['landing', 'buttons'] },

        props: {
            currentPanel: {
                type: String,
                required: false,
                default: '',
            },
        },
        data () {
            return {
                currentSelection:  '',
                enableBtn: false,
            };
        },
        computed: {
            ...mapState(['profile', 'ui']),
            brandList () {
                const brands = [...this.ui.brandOptions];
                // Using this to place the user's preferred brand in the first position.
                if (this.profile.preferred_brand) {
                    const preferredBrandIndex = brands.findIndex(brand => brand.value === this.profile.preferred_brand);
                    const firstBrand = brands[preferredBrandIndex];
                    brands.splice(preferredBrandIndex, 1);
                    brands.unshift(firstBrand);
                    return brands;
                }
                return brands;
            },
        },
        methods: {

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            highlightSelectedBrand (brand) {
                if (brand === this.profile.preferred_brand) {
                    return 'selected';
                }
                else {
                    return this.currentSelection === brand ? 'selected' : 'greyed-out';
                }
            },

            selectBrand (newSelection) {
                if (this.currentPanel === 'preferred_brand') {

                    if (this.currentSelection === newSelection) {
                        return;
                    }
                    this.currentSelection = newSelection;
                    this.enableBtn = true;
                    this.$emit('new-selection', newSelection);
                }
            },

            submitBrand () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: `Landing_BrandSwipe_${getBrand(this.currentSelection)}` });
                this.$router.replace('/register');
            },

        },
    };

