
    import BaseModal from './BaseModal';

    export default {
        name: 'RewardCatalogModal',

        i18nOptions: { namespaces: ['rewardcatalog_modal', 'buttons', 'dashboard'] },

        extends: BaseModal,

        props: {
            reward: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'rewardcatalog-modal',
                regex: /<span[^>]*>[\s\S]*?<\/span>/,
            };
        },
        computed: {
            handleRegEx () {
                return this.reward.description.match(this.regex);
            },

            handleDescription () {
                return this.handleRegEx ? this.reward.description.replace(this.handleRegEx[0], '') : this.reward.description;
            },

            handleDisclaimer () {
                return this.handleRegEx ? this.handleRegEx[0] : '';
            },
        },

        methods: {
            handleButtonClick () {
                this.closeModal(true);
            },
        },
    };
