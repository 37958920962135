
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { BaseCheckbox } from '@components/form';
    import BoxIcon from '@components/svg/BoxIcon.vue';
    import { BaseSelect } from '@components/form';
    import openModal, { HowItWorksModal, BrandSelectionModal } from '@public/js/modals';
    import loyalty from '../loyalty';
    import loyaltyOptIn from '../brand-email-optin';
    import BaseModal from './BaseModal';
    // import store from '../store/index';

    export default {
        name: 'BrandSelectionModal',

        components: {
            BaseCheckbox,
            BaseSelect,
            BoxIcon,
        },

        extends: BaseModal,

        data () {
            return {
                brand: '',
                brand_email_optin: false,
                modalName: 'brand-selection',
            };
        },

        i18nOptions: { namespaces: ['buttons', 'brand_selection'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
            brandList () {
                if (!this.ui.brandOptions.length) {
                    return [{ value: '', label: '' }];
                }

                const brands = [];

                for (const brand of this.ui.brandOptions) {
                    const tempBrand = {
                        value: brand.value,
                        label: this.$t(`brands.${brand.value}`),
                    };
                    brands.push(tempBrand);
                }
                return brands;
            },
        },

        mounted () {
            this.btnname = this.$t('next_btn');
        },

        beforeDestroy () {
            if (!this.profile.preferred_brand) {
                openModal(BrandSelectionModal);
            }
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
                updateProfile: 'profile/updateProfile',
            }),
            async selectBrand () {
                await this.updateProfile({ 'preferred_brand': this.brand });

                /* Since preferred brand is no longer set on enrollment, the LP preferred brand
                   enrollment email is no longer triggering. This is being setup to take care
                   of that in a new way. The this.updateProfile() event must complete first with
                   the actual preferred brand in order for this to work properly. */
                await loyalty.recordEvent('set_pref_brand');

                if (this.brand_email_optin) {
                    await this.updateProfile({ 'brand_email_optin': true });
                    await loyaltyOptIn.recordEvent(`${this.brand}_optin`);
                }

                openModal(HowItWorksModal);

                this.closeModal();
            },
            toggleOptIn () {
                this.brand_email_optin = !this.brand_email_optin;
            },
        },
    };
