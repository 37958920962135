
    import UploadIcon from '@components/svg/UploadIcon.vue';
    import { submitPhoto } from '../file-upload';
    import BaseModal from './BaseModal';
    import openModal, { UploadConfirmModal } from '.';

    export default {
        name: 'UploadPreviewModal',

        components: {
            UploadIcon,
        },

        extends: BaseModal,

        props: {
            file: {
                type: File,
                required: true,
            },
            isDisneyWish: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data () {
            return {
                loadingImage: true,
                modalName: 'file-preview',
                submitting: false,
                showDefaultPDF: false,
            };
        },

        mounted () {
            this.readFile();
        },

        methods: {
            closeModal (args) {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Receipt_Upload_Preview' });
                this.showDefaultPDF = false;
                this.$emit('close-modal', {
                    ...args,
                    clearValue: true,
                });
            },

            readFile () {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    if (evt.target.result.includes('application/pdf')) {
                        this.showDefaultPDF = true;
                    }
                    this.$refs.previewImage.src = evt.target.result;
                    this.loadingImage = false;
                };
                reader.readAsDataURL(this.file);
            },

            async submit () {
                this.submitting = true;

                try {
                    await submitPhoto(this.file);
                    this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Receipt_Upload_Preview' });
                    this.closeModal();
                    openModal(UploadConfirmModal, { isDisneyWish: this.isDisneyWish });
                }
                catch (err) {
                    // TODO: Are there any errors we need to handle from the back end or Veryfi?
                    console.error(err);
                }

                this.submitting = false;
            },
        },

        i18nOptions: { namespaces: ['file_preview', 'upload'] },
    };
