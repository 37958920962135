
    import BaseModal from './BaseModal';

    export default {
        name: 'AddressUpdateSuccessModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'address-update-success',
            };
        },

        i18nOptions: { namespaces: ['address_updates', 'buttons'] },

    };
