
import axios from 'axios';
import Vue from 'vue';

import api from '../../api';

const getDefaultState = () => Vue.observable({
    transactions: [],
});

const state = getDefaultState();

const getters = {};

const mutations = {
    updateTransactionHistory (state, data) {
        Vue.set(state, 'transactions', [...data]);
    },
};

const actions = {
    async getTransactionHistory ({ commit }) {
        try {
            const response = await axios.get(`${api.base}/profiles/events?is_activity_log=true`);

            commit('updateTransactionHistory', response.data.result.data);
        }
        catch (error) {
            console.error('failed to load transaction history', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
