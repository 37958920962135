
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    FaqPage = async () => import(
        /* webpackChunkName: 'faq' */
        '../views/FaqPage.vue'
    );

export default [
    { path: '/faq', component: FaqPage, meta: {
        public: true,
        activeInPhase: ':any',
        activeInMaint: true,
    } },

    { path: '/contact-form', component: FaqPage, meta: {
        public: true,
        activeInPhase: ':any',
    } },
];
