
    import { mapState, mapGetters, mapActions } from 'vuex';
    import TheHeader from '@components/TheHeader.vue';
    import TheFooter from '@components/TheFooter.vue';
    import TheNavigation from '@components/TheNavigation.vue';
    import openModal, { SessionExpirationModal, AddressUpdateModal } from './modals';
    import logClientError from './client-error';
    import webAnalytics from './plugins/WebAnalytics';


    export default {
        name: 'App',

        components: {
            TheHeader,
            TheFooter,
            TheNavigation,
        },

        i18nOptions: { namespaces: ['global', 'cookie_gate', 'landing'] },

        provide: {
            $webAnalytics: webAnalytics,
        },

        data () {
            return {
                showOverlay: false,
                webAnalyticsReady: false,
                pageNamesWithHeaderBg: ['error', 'expired', 'ineligible-age', 'prelaunch', 'dup-account', 'maintenance', 'paused-account'],
                aprilSweeps: false,
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
            }),

            checkIfSplash () {
                return (this.profile.preferred_brand === 'splash' && this.ui.pageName === 'dashboard') || (this.ui.pageName === 'dedicatedBrandPage' && this.$route.path.replace('/', '') === 'splash');
            },

            isAprilSweeps () {
                return false;
            },

            isDisneyWish () {
                return false;
            },

            shouldShowNavigation () {
                // TODO: exclude from any specific pages?
                return this.loggedIn;
            },

            showHeaderBg () {
                return this.pageNamesWithHeaderBg.includes(this.ui.pageName);
            },

            brandClass () {
                if (this.isDisneyWish) {
                    return `disney-wish ${this.profile.preferred_brand}`;
                }
                else {
                    return this.profile.preferred_brand || '';
                }
            },
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
        },

        mounted () {
            document.body.className = this.isDisneyWish ? 'disney-wish' : '';

            if (this.loggedIn && !this.profile.hasGoodAddress) {
                openModal(AddressUpdateModal);
            }
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            handleOverlay () {
                this.showOverlay = !this.showOverlay;
            },

            openProfileManager () {
                this.$refs.header.openProfileManager();
            },

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);

                // After the modal is closed.
                this.logOut();
                this.$router.replace('/');
            },
        },
    };
