
    import BaseModal from './BaseModal';

    export default {
        name: 'UploadConfirmModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['upload'] },

        props: {
            isDisneyWish: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        computed: {
            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },
        methods: {
            closePopup () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Receipt_Upload_Confirm' });
                this.closeModal();
            },
        },

    };
