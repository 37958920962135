
    import { mapState, mapGetters, mapActions } from 'vuex';
    import moment from 'moment';
    import openModal, { HowItWorksModal } from '@public/js/modals';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        props: {
            isDisneyWish: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            beforeSweepsExpires () {
                return moment(this.app.now).isBefore(moment('2023-09-30 17:00:00'));
            },

            aprilSweeps () {
                return `<a href="${this.app.tb2URL}public/fulfillment/april_sweeps_rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            yankees () {
                return `<a href="${this.app.tb2URL}public/fulfillment/yankees_rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            pilgrimage () {
                return `<a href="${this.app.tb2URL}public/fulfillment/pilgrimage_rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            kayaking () {
                return `<a href="${this.app.tb2URL}public/fulfillment/kayaking_rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            wishRules () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            preferredBrandRulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules_${this.profile.preferred_brand}.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },

            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openHowItWorks () {
                openModal(HowItWorksModal);
            },
        },
    };

