
    import { mapState } from 'vuex';
    import VueSlickCarousel from 'vue-slick-carousel';
    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import UploadIcon from '@components/svg/UploadIcon.vue';
    import EntryForm from '@components/EntryForm.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'PhotoUploadTipsModal',

        components: {
            VueSlickCarousel,
            UploadIcon,
            EntryForm,
        },

        extends: BaseModal,

        props: {
            isAprilSweeps: {
                type: Boolean,
                required: false,
                default: false,
            },
            slideIndex: {
                required: false,
                type: Number,
                default: 1,
            },
        },

        data () {
            return {
                modalName: 'photo-upload-tips-modal',
                currentSlide: 1,
                loading: true,
                slides: 5,
                over18: false,
                slickSettings: {
                    arrows: false,
                    infinite: false,
                    slidesToScroll: 1,
                    slideToShow: 1,
                    dots: true,
                    adaptiveHeight: true,
                },
            };
        },

        computed: {
            ...mapState(['profile']),
            isSplash () {
                return this.$route.path.replace('/', '') === 'splash' || this.profile.preferred_brand === 'splash';
            },
            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/april_sweeps_rules.pdf"${this.$t('links:rules_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
            isDisneyWishCheckBox () {
                if (this.isDisneyWish) {
                    return !this.over18;
                }
                else {
                    return false;
                }
            },
        },

        mounted () {
            // Avoids FOUC (flash of unstyled content)
            this.$nextTick(() => {
                this.loading = false;
            });

        },
        updated () {
            if (this.slideIndex > 1) {
                this.moveToSlide(this.slideIndex);
            }
        },

        methods: {
            beforeSlideChange (_, newSlide) {
                this.currentSlide = newSlide + 1;
            },

            nextSlide () {
                this.trackSlides();
                this.$refs.carousel.next();
            },

            moveToSlide (slideIndex) {
                this.$refs.carousel.goTo(slideIndex - 1);
            },

            handleSelected (event) {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'End_Prompt' });
                this.closeModal(event);
            },

            trackSlides () {
                switch (this.currentSlide) {
                    case 1:
                        this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Receipt_Requirements' });
                        break;
                    case 2:
                        this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Tips_For_Success' });
                        break;
                    default:
                        this.$webAnalytics.track('Click', { category: 'GoTo', label: `Step_${this.currentSlide}` });
                        break;
                }
            },
        },

        i18nOptions: { namespaces: ['upload_tips', 'buttons', 'intro'] },
    };
