
    import { mapActions, mapGetters, mapState } from 'vuex';
    import openModal, { HowItWorksModal } from '@public/js/modals';
    import { openPopup } from '@public/js/popup';
    import BrandSlider from './BrandSlider.vue';

    export default {
        name: 'SiteLinks',

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },
        components: {
            BrandSlider,
        },
        props: {
            brandsClosed: {
                type: Boolean,
            },
        },

        data () {
            return {
                showMenuItems: true,
            };
        },
        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            ...mapState(['app']),
        },

        methods: {
            brandItemClicked () {
                this.showMenuItems = true;
                this.onSelected();
                this.$emit('brands-open', false);
            },
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            onPopup (event) {
                openPopup(event);
                this.onSelected();
            },

            onSelected () {
                this.$emit('menu-item-selected');
            },

            async openHowItWorks () {
                openModal(HowItWorksModal);
            },
            openOurBrands () {
                this.showMenuItems = false;
                this.$emit('brands-open', true);
            },
        },
    };
