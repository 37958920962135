export default {
    methods: {
        handleAddressUpdateErrors (errors, name) {
            if (Array.isArray(errors)) {
                const fieldNames = ['address_line_1', 'city', 'state', 'zip', 'no_po_box'];
                for (const field of fieldNames) {
                    if (errors.includes(field)) {
                        if (field === 'no_po_box') {
                            return 'no_po_box';
                        }
                        else {
                            document.getElementsByClassName(`${field}${name}`)[0].classList.add('error');
                        }
                    }
                    else {
                        if (document.getElementsByClassName(`${field}${name}`)[0]) {
                            document.getElementsByClassName(`${field}${name}`)[0].classList.remove('error');
                        }
                    }
                }

                return true;
            }
            else {
                return false;
            }
        },
    },
};
