
    import openModal, { PollResultModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'PollConfirm',

        extends: BaseModal,

        i18nOptions: { namespaces: ['poll', 'buttons'] },

        methods: {
            pollResult () {
                this.closeModal();
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Poll_Confirm' });
                openModal(PollResultModal);
            },
        },

    };
