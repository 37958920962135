
    import { mapActions, mapGetters, mapState } from 'vuex';
    import moment from 'moment';
    import { BaseCheckbox, BaseInput, StateSelect } from '@components/form';
    import TheLoader from '@components/TheLoader.vue';
    import openModal, { AddressUpdateSuccessModal } from '../modals';
    import convertBrandToLongName from '../mixins/convertBrandToLongName';
    import handleAddressVerifyModals from '../mixins/handleAddressVerifyModals';
    import handleAddressUpdateErrors from '../mixins/handleAddressUpdateErrors';
    import BrandSelect from './BrandSelect.vue';


    export default {
        name: 'ProfileManager',

        i18nOptions: { namespaces: ['profile_manager', 'profile', 'global'] },

        components: {
            BaseCheckbox,
            BaseInput,
            BrandSelect,
            StateSelect,
            TheLoader,
        },

        mixins: [convertBrandToLongName, handleAddressVerifyModals, handleAddressUpdateErrors],

        props: {
            brandsOpen: {
                type: Boolean,
                required: false,
            },
            menuOpen: {
                type: Boolean,
            },
        },

        data () {
            return {
                currentPanel: '',
                currentBrand: '',
                selectionMade: false,
                isOpen: false,
                tempProfile: {},
                trackingEmailValue: [],
                optedOut: Boolean,
                loading: false,
                hasErrors: false,
            };
        },

        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            ...mapState(['profile', 'app', 'profile_manager', 'ui']),


            enrollmentDate () {
                return (this.profile && this.profile.enrollment) ? moment(String(this.profile.enrollment.created_at)).format('MM/DD/YYYY') : undefined;
            },

            handleStateName () {
                const country = this.app.userCountry;
                return this.profile.state.includes(`${country}-`) ? this.profile.state : `${country}-` + this.profile.state;
            },
        },

        watch: {
            isOpen (value, oldValue) {
                if (value !== oldValue) {
                    this.$emit('profile-manager-toggled', value);

                    if (value) {
                        // Make sure profile manager opens with panel selector
                        this.currentPanel = '';
                    }
                }
            },
        },

        mounted () {
            this.handleEmailOptOutBox();
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
                updateProfile: 'profile/updateProfile',
                updateAddress: 'profile/updateAddress',
            }),

            close () {
                this.isOpen = false;
            },

            getCurrentProfile () {
                this.tempProfile = { ...this.profile };
                /* Used to convert 2 char state back to 5 char for the state select dropdown */
                this.tempProfile.state = this.handleStateName;

                if (this.profile.lp_status == 'paused') {
                    this.$router.replace('/paused_account');
                }
            },

            loadBrand (brand) {
                this.currentBrand = brand;
                this.selectionMade = true;
            },

            open () {
                this.isOpen = true;
            },

            onSelected () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Menu_Expanded_Profile' });

                this.close();
                this.$emit('profile-item-selected');
            },

            updateBrand (brand) {
                this.updateProfile({ 'preferred_brand': brand });
            },

            returnToSelector () {
                this.currentPanel = '';
                this.selectionMade = false;
            },

            async saveBrand () {
                this.loading = true;
                await this.updateProfile({ 'preferred_brand': this.currentBrand });
                if (this.ui.pageName === 'dedicatedBrandPage') {
                    this.$router.replace(this.convertBrandToLongName(this.currentBrand));
                }
                this.loading = false;
                this.returnToSelector();
            },

            async saveProfileChanges () {
                this.setTracking();
                if (this.optedOut) {
                    this.tempProfile.program_email_optin = false;
                }
                else {
                    this.tempProfile.program_email_optin = true;
                }

                const data = await this.updateAddress(this.tempProfile);
                const addressValidationResponse = this.handleAddressVerifyModals(data);
                this.hasErrors = this.handleAddressUpdateErrors(addressValidationResponse, '-profile-manager-div');

                if (this.hasErrors) {
                    return;
                }

                this.optedOut = this.profile.program_email_optin;


                this.getCurrentProfile();
                this.returnToSelector();
            },
            handleEmailOptOutBox () {
                this.profile.program_email_optin == true ? this.optedOut = false : this.optedOut = true;
            },

            setEmailTracking () {
                this.trackingEmailValue = [];

                if (this.tempProfile.brand_email_optin == true) {
                    this.trackingEmailValue.push('brand_email_optin');
                }
                if (this.tempProfile.program_email_optin == true) {
                    this.trackingEmailValue.push('program_email_optin');
                }

                const preferenceID = this.trackingEmailValue.toString();
                if (preferenceID != '') {
                    this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Profile_Update_Email_Preference', value: preferenceID });
                }
            },

            setPanel (panel) {
                this.currentPanel = panel;

                // Get current profile whenever panel is selected.
                // Prevents unsaved changes from leaking between panels.
                if (panel == 'email') {
                    this.handleEmailOptOutBox();
                }
                this.getCurrentProfile();
            },
            setTracking () {
                let currentLabel = 'Profile_Update_Name';
                if (this.currentPanel === 'email') {
                    currentLabel = 'Profile_Update_Email_Preference';
                }
                else if (this.currentPanel === 'address') {
                    currentLabel = 'Profile_Update_Address';
                }
                this.$webAnalytics.track('Click', { category: 'GoTo', label: currentLabel });
            },

            async signOut () {
                this.onSelected();

                await this.logOut();
                this.$router.replace('/');
            },
        },
    };
