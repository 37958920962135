
    import { mapActions } from 'vuex';
    import { BaseInput, StateSelect } from '@components/form';
    import TheLoader from '@components/TheLoader.vue';
    import handleAddressVerifyModals from '../mixins/handleAddressVerifyModals';
    import handleAddressUpdateErrors from '../mixins/handleAddressUpdateErrors';
    import openModal, { AddressUpdateSuccessModal } from '../modals';
    import BaseModal from './BaseModal';


    export default {
        name: 'AddressUpdateModal',

        components: {
            BaseInput,
            StateSelect,
            TheLoader,
        },

        extends: BaseModal,

        mixins: [handleAddressVerifyModals, handleAddressUpdateErrors],

        data () {
            return {
                modalName: 'address-update',
                tempProfile: {
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                hasError: false,
                loading: false,
            };
        },

        i18nOptions: { namespaces: ['address_updates', 'profile', 'buttons'] },
        computed: {
            hasAllValues () {
                let hasAllFields = true;
                for (const key in this.tempProfile) {
                    if (!this.tempProfile[key] && key !== 'address_line_2') {
                        this.setError(key);
                        hasAllFields = false;
                    }
                    else {
                        this.removeError(key);
                    }
                }

                return hasAllFields;
            },
        },

        methods: {
            ...mapActions({
                updateAddress: 'profile/updateAddress',
            }),
            setError (key) {
                this.hasError = true;
                [...document.getElementsByClassName(`${key}-addr-div`)][0].classList.add('error');
            },
            removeError (key) {
                [...document.getElementsByClassName(`${key}-addr-div`)][0].classList.remove('error');
            },
            async saveProfileChanges () {
                this.loading = true;
                if (this.hasAllValues) {
                    this.hasError = false;
                    const data = await this.updateAddress(this.tempProfile);
                    const addressStatus = this.handleAddressVerifyModals(data);
                    this.hasError = this.handleAddressUpdateErrors(addressStatus, '-addr-div');

                    if (addressStatus === true) {
                        openModal(AddressUpdateSuccessModal);
                        this.closeModal();
                    }
                    else if (addressStatus === 'continue') {
                        this.closeModal();
                    }
                    else if (addressStatus === 'bad address') {
                        document.getElementById('addressUpdateBody').classList.add('error');
                    }
                }
                this.loading = false;
            },
        },
    };
