
    import BaseModal from './BaseModal';

    export default {
        name: 'IncompleteAddressModal',

        i18nOptions: { namespaces: ['rewardcatalog_modal', 'buttons'] },

        extends: BaseModal,

        data () {
            return {
                modalName: 'addressupdate-modal',
            };
        },
    };
