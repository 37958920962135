
    import BaseModal from './BaseModal';

    export default {
        name: 'ProfileSurveyCompleteModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['profile_survey', 'buttons'] },

    };
