
const capitalize = (string) => string.replace(/^./, str => str.toUpperCase());

const getBrand = brand => {
    switch (brand) {
        case 'deer':
            return 'Deerpark';
        case 'ice':
            return 'Icemountain';
        case 'poland':
            return 'Polandspring';
        default:
            return capitalize(brand);
    }
};

export { capitalize, getBrand };
