
    import { mapState } from 'vuex';
    import convertBrandToShortName from '../mixins/convertBrandToShortName';
    import BaseModal from './BaseModal';

    export default {
        name: 'ParticipationModal',

        extends: BaseModal,

        mixins: [convertBrandToShortName],

        data () {
            return {
                modalName: 'Participation',
            };
        },
        computed: {
            ...mapState(['profile', 'ui']),

            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },

            productName () {
                return this.$t(`${this.profile.preferred_brand}`);
            },
        },
        methods: {

            handleBrandSwitching () {
                if (this.ui.pageName === 'dedicatedBrandPage') {
                    return this.convertBrandToShortName(this.$route.path.replace('/', ''), true);
                }
                else {
                    return this.profile.preferred_brand;
                }
            },
        },
        i18nOptions: { namespaces: ['participation_product', 'buttons'] },
    };
