
    import BaseModal from './BaseModal';

    export default {
        name: 'ConfirmRedemptionModal',

        i18nOptions: { namespaces: ['rewardcatalog_modal', 'buttons', 'dashboard'] },

        extends: BaseModal,

        props: {
            reward: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'confirmredemption-modal',
            };
        },
    };
