
    import BaseModal from './BaseModal';

    export default {
        name: 'PrizeDetailsModal',

        components: {
        },

        extends: BaseModal,

        props: {
            slideIndex: {
                required: false,
                type: Number,
                default: 1,
            },
        },

        data () {
            return {
                modalName: 'prize-details-modal',
            };
        },

        computed: {
            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },
            rulesLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/rules.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:rules_copy')}</a>`;
            },
        },

        mounted () {
        },

        methods: {
        },

        i18nOptions: { namespaces: ['modal_content', 'buttons', 'intro'] },
    };
