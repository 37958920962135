
    import { mapActions, mapState } from 'vuex';
    import ActivityIcon from '@components/svg/ActivityIcon.vue';
    import BaseModal from './BaseModal';

    export default {
        name: 'ActivityModal',

        components: {
            ActivityIcon,
        },

        extends: BaseModal,

        props: {
            reward: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'activity-modal',
                rewardURL: '',
                rewardReason: '',
            };
        },

        computed: {
            ...mapState(['profile', 'transactionHistory', 'buttons']),

            productName () {
                return this.$t(`${this.profile.preferred_brand}`);
            },

            displayExpired () {
                return reward => reward.points > 0;
            },
        },

        created () {
            this.getTransactionHistory();
            this.getReceiptUrl(this.reward);
        },

        methods: {
            ...mapActions({
                getTransactionHistory: 'transactionHistory/getTransactionHistory',
            }),

            getEventTypeCopy (eventType) {
                return this.$t(`eventTypes.${eventType}`);
            },

            getFormattedDate (dateString) {
                const dateObj = new Date(dateString);
                return dateObj.toLocaleString(this.app.locale, {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                });
            },

            getFormattedDateAndTime (dateString) {
                const dateObj = new Date(dateString);
                return dateObj.toLocaleString(this.app.locale, {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZoneName: 'short',
                });
            },

            getPointsString (points) {
                return `${points > 0 ? '' : ''}${points}`;
            },

            getReceiptUrl (rewardData) {
                this.rewardURL = rewardData.event_attributes.rejected_receipt;
                this.rewardReason = rewardData.event_attributes.reject_reason;
                this.$webAnalytics.track('Click', { category: 'GoTo', label: `Activity_Log_Detai${this.reward.event_type}` });
            },

            CloseModal () {
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['activity_modal', 'buttons', 'transaction_history'] },
    };
