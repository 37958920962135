
    import { mapState } from 'vuex';
    import { BaseFileInput } from '@components/form/';

    export default {
        name: 'EntryForm',

        components: {
            BaseFileInput,
        },

        data () {
            return {
                loading: false,
                photoFileTypes: 'jpg, jpeg, png',
                photoUploadSizeMax: 10 * 1024 * 1024,
                photoError: '',
                tempPhoto: {
                    type: 'photo',
                    photo: '',
                    name: '',
                    file: undefined,
                },
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
        },

        methods: {

            selectPhoto (data) {
                this.photoError = '';
                if (data.error) {
                    this.photoError = this.$t(`errors.${data.error}`);
                    return;
                }

                this.tempPhoto.photo = data.photo;
                this.tempPhoto.name = data.name;
                this.tempPhoto.file = data.file;

                this.$emit('selected', this.tempPhoto);
            },

        },

        i18nOptions: { namespaces: ['buttons', 'file_uploader'] },

    };
