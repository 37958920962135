
    import { mapState, mapGetters } from 'vuex';
    import NavigationLink from '@components/NavigationLink.vue';
    import openModal, { PhotoUploadTipsModal, UploadPreviewModal, UploadLimitedModal } from '../modals';

    export default {
        name: 'TheNavigation',

        components: {
            NavigationLink,
        },

        props: {
            isDisneyWish: {
                type: Boolean,
                required: false,
                default: false,
            },
            isAprilSweeps: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        computed: {
            ...mapState(['profile']),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            async openUploader (tryAgain) {

                if (this.profile.receipt_limited) {
                    openModal(UploadLimitedModal, { isDisneyWish: this.isDisneyWish });
                    return;
                }

                const slideIndex = tryAgain ? 5 : 1;

                if (tryAgain == true) {
                    this.fileUploadResponse = await openModal(PhotoUploadTipsModal, { tryAgain, slideIndex, isDisneyWish: this.isDisneyWish, isAprilSweeps: this.isAprilSweeps });
                }
                else {
                    this.fileUploadResponse = await openModal(PhotoUploadTipsModal, { tryAgain, isDisneyWish: this.isDisneyWish, isAprilSweeps: this.isAprilSweeps });
                }

                if (this.fileUploadResponse) {
                    const uploadResponse = await openModal(UploadPreviewModal, this.fileUploadResponse,  { isDisneyWish: this.isDisneyWish });
                    if (uploadResponse.tryAgain) {
                        this.openUploader(uploadResponse.tryAgain);
                    }
                }
            },

            returnDashToBrand () {
                if (this.isDisneyWish) {
                    document.getElementById('app').className = `disney-wish ${this.profile.preferred_brand}`;
                }
                else {
                    document.getElementById('app').className = `${this.profile.preferred_brand}`;
                }
            },
        },
    };
