
    import { mapState, mapGetters, mapActions } from 'vuex';
    import UploadIcon from '@components/svg/UploadIcon.vue';
    import RewardIcon from '@components/svg/RewardIcon.vue';
    import BoxIcon from '@components/svg/BoxIcon.vue';
    import { openPopup } from '../popup';

    export default {
        name: 'HowItWorks',

        components: {
            UploadIcon,
            RewardIcon,
            BoxIcon,
        },

        props: {
            bgColor: {
                type: String,
                default: 'white',
            },
        },

        i18nOptions: { namespaces: ['landing', 'how_it_works'] },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

