
import axios from 'axios';
import api from './api';

const loyaltyOptIn = {
    async fetchEventDetails () {
        try {
            await axios.get(`${api.base}/profiles/events`);
            return true;
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    },
    async getBrandEmailOptins () {
        try {
            const response = await axios.get(`${api.base}/profiles/events/brand_email_optins`);
            return response.data;
        }
        catch (error) {
            console.error('failed to load', error);
        }
    },

    async recordEvent (event_type) {
        try {
            await axios.post(`${api.base}/profiles/record`, { event_type });
            this.fetchEventDetails();
            return true;
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    },
};

export default loyaltyOptIn;
