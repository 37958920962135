
    import { mapGetters } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'PollResultModal',

        extends: BaseModal,

        data () {
            return {
                loading: true,
                currentResults: [],
                barResults: [],
            };
        },

        computed: {
            ...mapGetters({
                currentPoll: 'profile/currentPoll',
            }),
        },

        created () {
            this.currentResults = [...this.ui.pollResults];
            this.cleanUpResults();
        },

        methods: {

            cleanUpResults () {
                let percentageTotal = 0;
                let barTopPercentage = 0;

                this.currentResults.forEach((element) => {
                    percentageTotal += element.percentage;

                    if (percentageTotal > 100) {
                        element.percentage = element.percentage - (percentageTotal - 100);
                    }

                    barTopPercentage = (barTopPercentage < element.percentage) ? element.percentage : barTopPercentage;

                });

                for (const result in this.currentResults) {
                    this.barResults[result] = (this.currentResults[result].percentage / barTopPercentage) * 100;
                }

            },

            closePollResultModal () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Poll_Results' });
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['poll', 'buttons'] },

    };
