export default {
    methods: {
        convertBrandToShortName (brand, ignoreArrowhead) {
            switch (brand) {
                case 'arrowhead':
                    return ignoreArrowhead ? 'arrowhead' : 'arrow';
                case 'deerpark':
                    return 'deer';
                case 'icemountain':
                    return 'ice';
                case 'ozarka':
                    return 'ozarka';
                case 'polandspring':
                    return 'poland';
                case 'purelife':
                    return 'pure';
                case 'splash':
                    return 'splash';
                case 'zephyrhills':
                    return 'zephyr';
                default:
                    return brand;
            }
        },
    },
};
