const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    modalName: null,
    showRegThanksModal: false,
    updateHowItWorkModal: false,
    // PLEASE READ ME!
    // If you are adding more brands you will need to add colors to the svgs for the class names
    // Update fb links when you add more brands on TafSection.vue
    // class names match the api
    brandOptions: [
        {
            value: 'arrowhead',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'deer',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'ice',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'ozarka',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'poland',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'pure',
            isNew: false,
            disclaimer: true,
        },
        {
            value: 'splash',
            isNew: true,
            disclaimer: false,
        },
        {
            value: 'zephyr',
            isNew: false,
            disclaimer: true,
        },
    ],
    pollResults: [],
});

const state = getDefaultState();

const getters = {
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName || newPage.$options.name;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    modalOpen (state, newModal) {
        state.modalName = newModal.modalName;
    },
    updateRegThanksModal (state, payload) {
        state.showRegThanksModal = payload;
    },
    updateHowItWorkModal (state, payload) {
        state.updateHowItWorkModal = payload;
    },
    modalClose (state) {
        state.modalName = null;
    },
    updatePoll (state, data) {
        state.pollResults = data;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
