
    import { mapState } from 'vuex';
    import axios from 'axios';
    import openModal, { PledgeCompletedModal } from '@public/js/modals';
    import api from '../api';
    import loyalty from '../loyalty';
    import convertBrandToShortName from '../mixins/convertBrandToShortName';
    import convertBrandToLongName from '../mixins/convertBrandToLongName';
    import BaseModal from './BaseModal';

    export default {
        name: 'PledgeModal',

        extends: BaseModal,

        mixins: [convertBrandToShortName, convertBrandToLongName],

        data () {
            return {
                modalName: 'pledge-modal',
                disabledBtn: false,
            };
        },

        computed: {
            ...mapState(['profile', 'ui']),

            productName () {
                return this.$t(`${this.profile.preferred_brand}`);
            },
            brandNameSelected () {
                if (this.ui.pageName === 'dedicatedBrandPage') {
                    return this.$route.path.replace('/', '');
                }
                else {
                    return this.profile.preferred_brand;
                }
            },
        },

        methods: {
            async doPledge () {
                this.disabledBtn = true;
                const response = await loyalty.recordEvent('made_better_pledge');
                if (response) {
                    /* ----------------------------------------------------- */
                    /* This is for April 2024 Sweeps and should be removed
                       or commented out after the sweeps are over. */
                    if (this.profile.preferred_brand && this.profile.preferred_brand != 'splash') {
                        try {
                            await axios.post(`${api.base}/direct_awards/send`, {
                                event_name: 'april_2024_sweeps',
                                referred_rs_id: null,
                                action: 'pledge',
                            })
                                .then(res => {
                                    console.log('POST /direct_awards/send res.data is', res.data);
                                    return null;
                                });
                        }
                        catch (error) {
                            const { response } = error;
                            console.log('POST /direct_awards/send error is', response);
                        }
                    }
                    /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
                    this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Pledge' });
                    this.disabledBtn = false;
                    this.closeModal();
                    openModal(PledgeCompletedModal);
                }
            },
        },

        i18nOptions: { namespaces: ['pledge_modal', 'buttons', 'participation_product'] },
    };
