
    import { mapMutations } from 'vuex';
    import axios from 'axios';
    import Validator from '@components/Validator';
    import { BaseInput } from '@components/form';
    import openModal, { ErrorModal, ViralThanksModal } from '@public/js/modals';
    import { viral as validatorGuards } from '../../../lib/validation/client-validators';
    import api from '../api';
    import store from '../store/index';
    import BaseModal from './BaseModal';

    const viralConfig = {"_public":true,"maxFields":1,"displayCAConfirm":true};
    export default {
        name: 'ViralModal',
        components: {
            Validator,
            BaseInput,
        },


        extends: BaseModal,

        data () {
            return {
                modalName: 'viral',
                loading: false,
                numFields: viralConfig.maxFields,
                displayCAConfirm: viralConfig.displayCAConfirm,
                formData: {},
                validatorGuards,
                referralCode: '',
                referCount: store.state.profile.completedSocialShares,
                friendLimited: false,
            };
        },

        computed: {
            termsLink () {
                return `<a href="${this.app.tb2URL}public/fulfillment/terms.pdf" title="${this.$t('links:terms_condition_copy')} ${this.$t('links:pdf')}" target="_blank">${this.$t('links:terms_condition_copy')}</a>`;
            },
        },

        created () {
            this.getReferralCode();
        },

        i18nOptions: { namespaces: ['viral'] },

        methods: {
            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            async doTAF () {
                const { formData, profile } = this;

                // Optionally provided for client-side validation, if available.
                formData.selfEmail = profile.email;
                formData.referral_code = this.referralCode;

                await this.$refs.validator.validate();

                if (this.$refs.validator.isValid) {
                    this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Taf' });
                }
                else {
                    // Don't continue submitting.
                    return;
                }

                this.loading = true;

                try {
                    await axios.post(`${api.base}/viral`, formData)
                        .then(res => {
                            this.friendLimited = res.data.friendLimited;
                            return null;
                        });
                    if (!this.friendLimited) {
                        this.closeModal();
                        this.referCount++;
                        this.updateProfile({ 'completedSocialShares': this.referCount });
                        openModal(ViralThanksModal);
                    }
                }
                catch (error) {
                    const { response } = error;

                    if (response && response.data && response.data.errors) {
                        this.$refs.validator.setAllErrors(response.data.errors);
                    }
                    else {
                        openModal(ErrorModal);
                    }
                }

                this.loading = false;
            },

            copyToClipboard () {
                const siteUrlInput = document.getElementById('viral_link');
                if (!navigator.clipboard) {
                    siteUrlInput.removeAttribute('disabled');
                    siteUrlInput.focus();
                    siteUrlInput.select();
                    document.execCommand('copy');
                    siteUrlInput.setAttribute('disabled', 'disabled');
                }
                else {
                    navigator.clipboard.writeText(this.app.siteURL);
                }
                siteUrlInput.classList.add('selected');
            },

            getReferralCode () {
                // Using traditional Promise chain syntax instead of await
                // since we can let them all run simultaneously
                axios.post(`${api.base}/profiles/referrals`, { channel: 'email' })
                    .then((response) => {
                        this.referralCode = response.data.referral_code;
                        return response;
                    })
                    .catch((error) => {
                        console.error('error getting referral code', error);
                    });
            },

            unselect () {
                const siteUrlInput = document.getElementById('viral_link');
                siteUrlInput.classList.remove('selected');
            },
        },
    };
