
    import { mapState, mapGetters } from 'vuex';
    import ProfileManager from '@components/ProfileManager.vue';
    import SiteLinks from '@components/SiteLinks.vue';


    export default {
        name: 'TheHeader',

        components: {

            ProfileManager,
            SiteLinks,
        },

        data () {
            return {
                isMenuOpen: false,
                isProfileManagerOpen: false,
                brandsOpen: false,
            };
        },

        computed: {
            ...mapState(['app']),

            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            h1ImageSource () {
                return require(`../../img/drop${this.isMenuOpen ? '' : '-color'}.png`);
            },

            handleMenuIcon () {
                if (this.isMenuOpen && (this.isProfileManagerOpen || this.brandsOpen)) {
                    return 'fa-arrow-alt-left';
                }
                else if (this.isMenuOpen && !this.isProfileManagerOpen) {
                    return 'fa-times';
                }
                else if (!this.isMenuOpen) {
                    return 'fa-bars';
                }
                return '';
            },

            showMenu () {
                return !['/faq'].includes(this.$route.path);
            },
        },

        watch: {
            isMenuOpen (value) {
                this.$emit('menu-toggled', value);
            },
        },

        mounted () {
            // Focus the h1 element to force screen readers back to the top after a navigation.
            // This will ensure screen readers will read the page again.
            // https://a11y-guidelines.orange.com/en/articles/single-page-app/#notify-user-of-page-changes
            this.$router.afterEach(() => this.$refs.h1.focus());
        },

        methods: {
            close () {
                this.isMenuOpen = false;
                this.$emit('menu-close');
                this.handleMenuIcon;
            },

            handleMenuButtonClick () {
                if (this.isMenuOpen) {
                    if (this.isProfileManagerOpen || this.brandsOpen) {
                        if (this.brandsOpen) {
                            this.brandsOpen = false;
                            this.handleMenuIcon;
                        }
                        else {
                            this.$refs.profileManager.close();
                        }
                    }
                    else {
                        this.isMenuOpen = false;
                        this.handleMenuIcon;
                    }
                }
                else {
                    this.isMenuOpen = true;
                    this.$emit('menu-opened');
                }

                if (this.loggedIn && this.isMenuOpen) {
                    this.$webAnalytics.track('View Screen', { category: 'pageView', label: 'Menu_expanded_Logged_In' });
                }
                else {
                    this.$webAnalytics.track('View Screen', { category: 'pageView', label: 'Menu_Expanded_LoggedOut' });
                }
            },

            handleMenuToggled (value) {
                this.isMenuOpen = value;

                if (value) {
                    document.body.classList.add('lock-scrolling');
                    window.scrollTo(0, 0);
                }
                else {
                    document.body.classList.remove('lock-scrolling');
                }
            },

            handleProfileManagerToggled (value) {
                this.isProfileManagerOpen = value;
            },

            notifyBrandsOpen (openOrClosed) {
                this.brandsOpen = openOrClosed;
            },

            openProfileManager () {
                this.$webAnalytics.track('View Screen', { category: 'pageView', label: 'Menu_Expanded_Profile' });

                this.handleMenuButtonClick();
                this.$refs.profileManager.open();
            },
        },
    };
