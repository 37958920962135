
    import { mapState,  mapMutations, mapGetters } from 'vuex';
    import moment from 'moment';
    import openModal, { BrandGateModal } from '@public/js/modals';
    import { getBrand } from '../utils/trackUtils';
    import convertBrandToLongName from '../mixins/convertBrandToLongName';

    export default {
        name: 'BrandSlider',
        i18nOptions: { namespaces: ['dashboard'] },

        mixins: [convertBrandToLongName],

        props: {
            allBrandsDisplayed: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data () {
            return {
                currentSelection:  '',
                enableBtn: false,
                openAllBrands: false,
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            brandList () {
                // Using this to place the user's preferred brand in the first position.
                const brands = [...this.ui.brandOptions];
                if (this.profile.preferred_brand) {
                    const preferredBrandIndex = brands.findIndex(brand => brand.value === this.profile.preferred_brand);
                    const firstBrand = brands[preferredBrandIndex];
                    brands.splice(preferredBrandIndex, 1);
                    brands.unshift(firstBrand);
                    return brands;
                }
                return brands;
            },
        },

        methods: {

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            checkIfNew (isNew) {
                return isNew && moment(this.app.now).isBefore(moment('2024-05-01 00:00:00'));
            },

            handleShowBrands () {
                this.openAllBrands = !this.openAllBrands;
                const hiddenBrands = [...document.querySelectorAll('.brand-buttons .hidden-button')];
                hiddenBrands.forEach(brand => {
                    brand.classList.toggle('hidden');
                });
                document.getElementById('brandButtonsWrapper').classList.toggle('open');
                this.$emit('unhidden');
            },
            selectBrand (newSelection) {
                if (this.currentSelection === newSelection) {
                    return;
                }
                this.currentSelection = newSelection;
                this.enableBtn = true;
                this.$emit('clicked');
            },

            submitBrand () {
                this.$webAnalytics.track('Click', { category: 'GoTo', label: `Landing_BrandSwipe_${getBrand(this.currentSelection)}` });
                this.$router.replace('/register');
            },

            handleBrandRoute (clickedBrand) {
                if (this.loggedIn) {
                    if (clickedBrand === this.profile.preferred_brand) {
                        this.$router.push(`/${this.convertBrandToLongName(this.profile.preferred_brand)}`);
                    }
                    else {
                        openModal(BrandGateModal, { selectedBrand: clickedBrand });
                    }
                }
                else {
                    this.$router.push(`/${this.convertBrandToLongName(clickedBrand)}`);
                }

            },
        },
    };

