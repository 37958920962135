export default {
    methods: {
        convertBrandToLongName (brand) {
            switch (brand) {
                case 'deer':
                    return 'deerpark';
                case 'ice':
                    return 'icemountain';
                case 'poland':
                    return 'polandspring';
                case 'pure':
                    return 'purelife';
                case 'zephyr':
                    return 'zephyrhills';
                default:
                    return brand;
            }
        },
    },
};
