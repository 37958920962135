
    import { mapActions } from 'vuex';
    import openModal, { AddressUpdateModal, AddressUpdateSuccessModal } from '../modals';
    import handleAddressVerifyModals from '../mixins/handleAddressVerifyModals';
    import BaseModal from './BaseModal';

    export default {
        name: 'AddressConfirmModal',

        extends: BaseModal,

        mixins: [handleAddressVerifyModals],

        props: {
            address: {
                type: Object,
                required: false,
                default () {
                    return { address1: '', address2: '', city: '', state: '', zip: '' };
                },
            },
            continue: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data () {
            return {
                modalName: 'address-confirm',
                tempProfile: {
                    address_line_1: this.address?.address1,
                    address_line_2: this.address?.address2,
                    city: this.address?.city,
                    state: this.address?.state,
                    zip: this.address?.zip,
                },
            };
        },

        i18nOptions: { namespaces: ['address_updates'] },

        methods: {
            ...mapActions({
                updateAddress: 'profile/updateAddress',
            }),
            async saveProfileChanges () {

                const data = await this.updateAddress(this.tempProfile);
                const addressStatus = this.handleAddressVerifyModals(data);

                if (addressStatus === true) {
                    openModal(AddressUpdateSuccessModal);
                    this.closeModal();
                }
                else if (addressStatus === 'continue') {
                    this.closeModal();
                }
            },
            backToAddressUpdate () {
                openModal(AddressUpdateModal);
                this.closeModal();
            },
        },
    };
