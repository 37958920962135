function setDOBArrayData () {
    const dobConfig = {
        months: 12,
        days: 31,
        minYear: 1910,
        maxYear: new Date().getFullYear(),
    };

    const
        days = buildArray(dobConfig.days),
        months = buildArray(dobConfig.months),
        years = buildYearsArray(dobConfig.minYear, dobConfig.maxYear);

    return {
        days,
        months,
        years,
    };
}

function buildArray (max) {
    const result = [];

    for (let i = 1; i <= max; i++) {
        result.push({ label: i, value: i });
    }

    return result;
}

function buildYearsArray (minYear, maxYear) {
    const years = [];

    for (let i = minYear; i <= maxYear; i++) {
        years.unshift({ label: i, value: i });
    }

    return years;
}

const dobData = setDOBArrayData();

export default dobData;
