
    export default {
        inheritAttrs: false,
        props: {
            'value': {
                type: String,
                default: '',
                required: true,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            name: {
                type: String,
                required: true,
                default: '',
            },
            inputType: {
                type: String,
                required: false,
                default: 'text',
            },
        },
    };
