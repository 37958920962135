
    import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
    import { BaseRadioGroup } from '@components/form';
    import openModal, { PollConfirmModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'PollModal',

        components: {
            BaseRadioGroup,
        },

        extends: BaseModal,

        data () {
            return {
                poll_answer: '',
                poll1_concertsports: '',
                poll_name: '',
                modalName: 'poll-modal',
            };
        },

        computed: {
            ...mapGetters({
                currentPoll: 'profile/currentPoll',
            }),
            ...mapState(['profile']),

            buttonDisabled () {
                return this.poll_answer === '';
            },

            pollOptions () {
                const options = [];
                let answerNumber = 0;
                do {
                    const question = {};
                    answerNumber += 1;
                    question.label = this.$t(`${this.currentPoll}_answer_${answerNumber}`);
                    question.value = this.$t(`${this.currentPoll}_answer_${answerNumber}`);
                    options.push(question);
                } while (this.$t(`${this.currentPoll}_answer_${answerNumber}`));
                options.pop();

                return options;
            },
        },

        methods: {
            ...mapActions({
                'updatePollRoute': 'profile/updatePoll',
                'updateProfile': 'profile/updateProfile',
            }),

            ...mapMutations({
                'updatePoll': 'ui/updatePoll',
            }),

            async doPollUpdate (e) {
                const pollAnswer = {};

                pollAnswer[this.currentPoll] = this.poll_answer;
                this.loading = true;

                try {
                    const { poll_results } = await this.updatePollRoute(pollAnswer);
                    this.updatePoll(poll_results);
                    this.isProfileUpdate = false;
                    this.loading = false;
                    this.completeSurvey();
                }
                catch (err) {
                    console.error(err);
                    this.isProfileUpdate = true;
                    this.loading = false;
                }

            },

            completeSurvey () {
                this.closeModal();
                this.$webAnalytics.track('Click', { category: 'GoTo', label: 'Poll_Question' });
                openModal(PollConfirmModal);
            },
        },

        i18nOptions: { namespaces: ['poll'] },
    };
