
    export default {
        name: 'NavigationLink',

        i18nOptions: { namespaces: ['navigation'] },

        props: {
            href: {
                type: String,
                required: true,
            },

            isActive: {
                type: Boolean,
                required: true,
            },

            name: {
                type: String,
                required: true,
            },
        },

        computed: {
            imageSource () {
                return require(`../../img/navigation/${this.name}-${this.isActive ? 'active' : 'default'}.png`);
            },
        },
    };
